const groupBy = require('../utils/groupBy');
const daysSum = require('./daysSum');
const durationSum = require('./durationSum');
const { pickBy, isNil } = require('ramda');
const cleanNil = e => pickBy((v) => !isNil(v), e);

module.exports = function(entries) {
  let simpleEntries = entries
    .filter(e => Boolean(e))
    .map(e => ({
      icon: e.icon,
      duration: e.duration || 'PT0S',
      day: e.day,
      name: e.name,
      name18: e.name18
    }))
    .map(cleanNil)
    .filter(e => !isNil(e.icon));

  let groups = groupBy(simpleEntries, 'icon');
  let totals = Object.entries(groups).map(pair => {
    return pair[1].reduce((acc, entry) => {
      acc.duration = durationSum(acc.duration, entry.duration);
      acc.day = daysSum(acc.day, entry.day);
      return acc;
    });
  });
  return totals.map(cleanNil)
};
