const { DateTime } = require('luxon');
module.exports = function(isoFrom, isoUntil) {
  const from = DateTime.fromISO(isoFrom);
  const until = DateTime.fromISO(isoUntil || isoFrom);
  let current = from;
  let dates = [];
  while (current <= until) {
    dates = [...dates, current.toISODate()];
    current = current.plus({ days: 1 });
  }
  return dates;
};
