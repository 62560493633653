const Duration = require('luxon').Duration;

const computeSumsByOp = require('../common/computeSumsByOp');
const summaryCreate = require('./summaryCreate');

function findOpDuration(summaries, op) {
  let summary = summaries.find(s => s.op === op);
  if (summary) {
    let duration = Duration.fromISO(summary.duration);
    return summary.negative ? duration.negate() : duration;
  } else {
    return Duration.fromISO('PT0S');
  }
}

function replace(fromSummaries, op, duration) {
  let summaries = [...fromSummaries];
  let index = summaries.findIndex(s => s.op === op);
  if (index >= 0) {
    let summary = JSON.parse(JSON.stringify(summaries[index]));
    summary.duration = duration.toISO();
    summary.negative = duration.as('milliseconds') < 0;
    summaries[index] = summary;
  } else {
    summaries.push(summaryCreate(op, duration.normalize().toISO()));
  }
  return summaries;
}

module.exports = function (fromSummaries) {
  let summaries = computeSumsByOp(fromSummaries);

  let fromDue = findOpDuration(summaries, 'due');
  // due = settingsDue - holidays - vacations - leave - service
  // this one is already computed on each week

  let fromMax = findOpDuration(summaries, 'max');
  // this one is already computed each week

  let fromNormal = findOpDuration(summaries, 'normal');
  let fromOver = findOpDuration(summaries, 'over-time');
  let fromExtra = findOpDuration(summaries, 'extra-time');
  let fromPause = findOpDuration(summaries, 'pause');
  // let fromRecover = findOpDuration(summaries, 'recovery');
  // TODO check that none of the above is negative

  let worked = fromNormal
    .plus(fromOver)
    .plus(fromExtra)
    .minus(fromPause) // in fact this one is not used, it is already simplified
    // .minus(fromRecover) // this was added on v3
    .normalize();

  // worked = normal + overtime + extratime - pause

  let zero = Duration.fromMillis(0);
  let over = zero;
  let extra = zero;
  let recover = zero;

  if (worked > fromMax) {
    extra = worked.minus(fromMax);
    worked = fromMax;
  }

  if (worked > fromDue) {
    over = worked.minus(fromDue).normalize();
    worked = fromDue;
    recover = zero
  } else if (worked < fromDue) {
    recover = fromDue.minus(worked).normalize();
  }

  let normal = worked;

  // compute the total
  let total = normal.plus(over).plus(extra).normalize();

  // publish summaries

  summaries = replace(summaries, 'normal', normal);
  summaries = replace(summaries, 'over-time', over);
  summaries = replace(summaries, 'extra-time', extra);
  summaries = replace(summaries, 'recovery', recover);
  summaries = replace(summaries, 'pause', zero);
  summaries = replace(summaries, 'total-worked', total);

  return summaries.filter(s => s.force || s.duration !== 'PT0S' || !!s.day);
};
