<template>
  <main>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"
              ><a>{{ $t('breadcrumb_home') }}</a></router-link
            >
            <router-link
              class="is-hidden-mobile"
              tag="li"
              :to="{ name: 'companies' }"
              ><a>{{ $t('breadcrumb_companies') }}</a></router-link
            >
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"
              ><a><company-name :company="company" /></a
            ></router-link>
            <router-link
              class="is-hidden-mobile"
              tag="li"
              :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }"
              ><a>{{ $t('breadcrumb_accounts') }}</a></router-link
            >
            <router-link
              tag="li"
              :to="{ name: 'account', params: { cid, aid } }"
              ><a><account-name :account="account" /></a
            ></router-link>
            <router-link
              tag="li"
              :to="{ name: 'account', params: { cid, aid } }"
              ><a>{{ $t('breadcrumb_reports') }}</a></router-link
            >
            <router-link
              tag="li"
              :to="{ name: 'report-sheets', params: { cid, aid } }"
              ><a>{{ $t('breadcrumb_report_sheets') }}</a></router-link
            >
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <div class="columns">
          <div class="column">
            <sqr-input-week
              label="report_sheets_start_date"
              v-model="from"
              @change="load()"
            />
          </div>
          <div class="column">
            <sqr-input-week
              label="report_sheets_end_date"
              v-model="until"
              @change="load()"
            />
          </div>
          <div class="column">
            <report-buttons @selected="select($event)" />
          </div>
        </div>
      </div>
    </section>
    <section class="px-2 md:px-0 py-12">
      <div class="container">
        <h1 class="title">{{ $t('report_sheets_title') }}</h1>
        <h2 class="subtitle">
          <span>
            {{ $t('report_sheets_subtitle') }}
          </span>
          <span>
            {{ $tc('report_sheets_sheets', weeks, { weeks }) }}
          </span>
          •
          <span>
            {{ $tc('report_sheets_from') }}
            {{ from | week }}
            {{ $tc('report_sheets_until') }}
            {{ until | week }}
          </span>
          •
          <span>
            {{ $tc('report_sheets_from') }}
            {{ fromDay | date }}
            {{ $tc('report_sheets_until') }}
            {{ untilDay | date }}
          </span>
          <a class="text-gray-200" @click="sRebuild(from)">&pi;</a>
        </h2>

      </div>
    </section>
    <section class="px-2 md:px-0 py-4" v-if="report">
      <div class="container">
        <summaries :summaries="report.summaries" />
      </div>
    </section>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <label class="checkbox">
          <input type="checkbox" v-model="details" />
          {{ $t('report_sheets_details_help') }}
        </label>
      </div>
    </section>
    <section class="px-2 md:px-0 pt-4" v-if="details">
      <div class="container">
        <report-sheets-details :report="sRecord" :from="from" :until="until" />
      </div>
    </section>
  </main>
</template>

<script>
import { DateTime } from 'luxon';

import computeReportSheets from 'sqr-wotime-core/reports/sheets';

import account from './account';
import sReport from './reportSheets.js';

import week from '@/filters/week';
import date from '@/filters/date';

import SqrInputWeek from '@/sqrd/SqrInputWeek';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
import ReportButtons from '@/components/ReportButtons';
import Summaries from '@/components/Summaries';
import ReportSheetsDetails from '@/components/ReportSheetsDetails';

import yearBoundaries from '@/utils/yearBoundaries';

export default {
  name: 'ReportWork',
  mixins: [account, sReport],
  filters: { week, date },
  components: {
    SqrInputWeek,
    CompanyName,
    AccountName,
    ReportButtons,
    Summaries,
    ReportSheetsDetails,
  },
  data() {
    return {
      from: '',
      until: '',
      details: false,
    };
  },
  computed: {
    fromDay() {
      return this.from + '-1';
    },
    untilDay() {
      return this.until + '-7';
    },
    weeks() {
      const from = DateTime.fromISO(this.from);
      const until = DateTime.fromISO(this.until).endOf('week');
      if (!from.isValid) return 0;
      if (!until.isValid) return 0;
      return from.until(until).count('week');
    },
    report() {
      if (!this.sRecord) return null;
      const sheets = Object.values(this.sRecord.sheetsById);
      const query = { startWeek: this.from, endWeek: this.until };
      return computeReportSheets(query, sheets);
    },
  },
  mounted() {
    this.select(DateTime.local().weekYear);
  },
  methods: {
    select(weekYear) {
      const bounds = yearBoundaries(weekYear);
      this.from = bounds.weekFrom;
      this.until = bounds.weekUntil;
      this.load();
    },
    load() {
      this.sLoad(this.from, this.until);
    },
  },
};
</script>
