<template>
  <div :class="dayClass" v-if="sheet">
    <div class="card">
      <div class="card-header-custom is-unselectable" @click="toggle">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item" v-if="!opened">
              <fa :icon="toggleIcon" fixed-width />
            </div>
            <div class="level-item">
              <div class="card-header-custom-title">
                <span class="is-hidden-mobile">{{ date | weekDayLong }}</span>
                <span class="is-hidden-tablet">{{ date | weekDay }}</span>
              </div>
            </div>
          </div>
          <div class="level-right">
            <week-day-total
              v-for="total in totals"
              :key="total.icon"
              :total="total"
            />
          </div>
        </div>
      </div>
      <div class="" v-show="toggled || opened">
        <transition-group
          name="fade"
          enter-active-class="fadeIn"
          leave-active-class="fadeOut"
        >
          <week-day-entry
            v-for="entry in entries"
            :key="entry.id"
            :entry="entry"
            @change="entryChange($event)"
            @remove="entryRemove($event)"
            :disabled="disabled"
          />
        </transition-group>
      </div>
      <div class="card-footer" v-show="toggled">
        <a
          class="card-footer-item has-text-black"
          v-for="model in entryModelsShort"
          :key="model.id"
          @click="!disabled && entryAdd({ date, model })"
          :disabled="disabled"
        >
          <!-- tabindex="" -->
          <span class="icon">
            <fal>
              <fa :icon="['fal', model.icon]" />
              <fa :icon="['fal', 'plus']" transform="shrink-6 right-16" />
            </fal>
          </span>
        </a>
        <a
          class="card-footer-item has-text-black-bis"
          :disabled="disabled"
          @click="modal = !disabled && true"
        >
          <span>{{ $t('week_day_more') }}</span>
          <span class="icon">
            <fa :icon="['fal', 'external-link']" />
          </span>
        </a>
      </div>
    </div>
    <entry-model-modal
      v-if="modal"
      @select="entryAdd({ date, model: $event })"
      @close="modal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import toggle from './toggle';
import WeekDayEntry from './WeekDayEntry';
import WeekDayTotal from './WeekDayTotal';
import EntryModelModal from './EntryModelModal';

import weekDayLong from '@/filters/weekDayLong';
import weekDay from '@/filters/weekDay';

export default {
  name: 'WeekDay',
  mixins: [toggle],
  components: { EntryModelModal, WeekDayTotal, WeekDayEntry },
  filters: { weekDayLong, weekDay },
  props: {
    sheet: Object,
    date: String,
    opened: Boolean,
    disabled: Boolean,
  },
  computed: {
    ...mapGetters('company', { company: 'record' }),
    entryModels() {
      return this.company?.entryModels ?? [];
    },
    entryModelsShort() {
      return this.entryModels.length > 7
        ? this.entryModels.slice(0, 7)
        : this.entryModels;
    },
    dayClass() {
      return {
        'day-toggled': this.toggled,
        'day-opened': this.opened,
      };
    },
    entries() {
      if (!this.date) return [];
      const date = this.date;
      const timeEntriesById = this.sheet?.timeEntriesById ?? {};
      const res = Object.entries(timeEntriesById)
        .filter(([, entry]) => entry && entry.date === date)
        .map(([id, entry]) => ({ ...entry, id }));
      return res;
    },
    totals() {
      return this.sheet?.totalsByDay?.[this.date] ?? [];
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  methods: {
    entryAdd({ date, model }) {
      this.$emit('add', { date, model });
    },
    entryChange(payload) {
      this.$emit('change', payload);
    },
    entryRemove(payload) {
      this.$emit('remove', payload);
    },
  },
};
</script>

<style scoped>
.day-toggled {
  padding-bottom: 3rem;
}

.day-opened {
  padding-bottom: 1rem;
}

.card-header-custom {
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(10, 10, 10, 0.1);
  padding: 0.75rem;
}

.card-header-custom-title {
  font-weight: 500;
}
</style>
