import { Duration } from 'luxon';

export default function (duration) {
  if (!duration) return '';
  let value = Duration.fromISO(duration);
  if (!value.isValid) {
    return 'NaN';
  } else if (value.valueOf() === 0) {
    return '';
  } else {
    return value.shiftTo('hours', 'minutes').toFormat('hh:mm');
  }
}
