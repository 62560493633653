// import {isNil} from 'ramda';
import durationTime from './durationTime';
import days from './days';

export default value => {
  if (!value) {
    return '';
  }
  const prefix = value.negative ? '-' : '';

  if (value.day && value.duration) {
    return (
      prefix +
      [days(value.day), durationTime(value.duration)]
        .filter(v => Boolean(v))
        .join('+')
    );
  } else if (value.duration) {
    return prefix + durationTime(value.duration);
  } else if (typeof value.day === 'number') {
    return prefix + days(value.day);
  } else {
    return 'N/A';
  }
};
