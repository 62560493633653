import { mapState, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import buildReportSheets from '@/utils/buildReportSheets';

export default {
  computed: {
    ...mapState('reportSheets', {
      sRecord: 'record',
      sExists: 'exists',
      sPath: 'path',
      sId: 'id',
      sLoading: 'loading',
      sLoadError: 'loadError'
    })
  },
  methods: {
    ...mapActions('reportSheets', {
      sSub: 'sub',
      sInit: 'init',
      sSave: 'save'
    }),
    async sLoad(from, until) {
      const weekYear = DateTime.fromISO(from).endOf('week').weekYear;
      const weekYearUntil = DateTime.fromISO(until).endOf('week').weekYear;
      if (weekYear < 2000) return;
      if (weekYear !== weekYearUntil) return;
      const id = `${weekYear}-sheets`;
      await this.sSub({
        path: ['companies', this.cid, 'accounts', this.aid, 'reports'],
        id
      });
      if (!this.sExists) this.sRebuild(from, until);
    },
    async sRebuild(from) {
      const report = await buildReportSheets(this.cid, this.aid, from);
      try {
        await this.sInit({ path: this.sPath, id: this.sId, record: report });
        await this.sSave();
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
};
