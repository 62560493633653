const models = require('./summaryModels');

module.exports = function (op, duration, day = 0) {
  let model = models().find(m => m.op === op);

  let summary = {op};
  if (model) {
    summary = {...model};
  }

  /* eslint-disable */
  if (!duration) {

  } else if (typeof duration === 'string') {
    summary.duration = duration;
    summary.negative = false;
  } else if (typeof duration === 'object') {
    summary.duration = duration.toISO();
    summary.negative = duration.as('milliseconds') < 0;
  }
  summary.day = day;
  return summary;
};