import { DateTime } from 'luxon';
import buildRef from '@/utils/buildRef';
import { fromPairs, pick } from 'ramda';

export default async function(cid, aid, pFrom) {
  try {
    const weekYear = DateTime.fromISO(pFrom).endOf('week').year;
    const from = DateTime.fromObject({ weekYear, weekNumber: 1 });
    const until = DateTime.fromObject({
      weekYear,
      weekNumber: from.weeksInWeekYear
    });
    const ref = buildRef(['companies', cid, 'accounts', aid, 'sheets']);
    const query = ref
      .where('startDate', '>=', from.toISODate())
      .where('startDate', '<=', until.toISODate());
    const res = await query.get();
    const keys = [
      'notices',
      'noticesCount',
      'summaries',
      'startDate',
      'endDate',
      'path',
      'status'
    ];
    const pairs = res.docs.map(snap => [snap.id, pick(keys, snap.data())]);
    const sheetsById = fromPairs(pairs);
    const report = {
      name: `${weekYear}`,
      dateFrom: from.toISODate(),
      dateUntil: until.endOf('week').toISODate(),
      sheetsById
    };
    return report;
  } catch (error) {
    return Promise.reject(error);
  }
}
