<template>
  <div v-if="sheet">
    <week-day
      v-for="date in dates"
      :key="date"
      :sheet="sheet"
      :date="date"
      :disabled="disabled"
      :opened="opened"
      @add="$emit('add', $event)"
      @change="$emit('change', $event)"
      @remove="$emit('remove', $event)"
    />
  </div>
</template>

<style scoped>
.card-header {
  cursor: pointer;
}
</style>

<script>
import WeekDay from './WeekDay';
import days from 'sqr-wotime-core/dates/days';

export default {
  name: 'WeekDays',
  components: { WeekDay },
  props: {
    sheet: { type: Object },
    opened: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    dates() {
      if (!this.sheet) return [];
      return days(this.sheet.startDate, this.sheet.endDate);
    },
  },
};
</script>
