<template>
  <article>
    <div v-for="sheet in sheets" :key="sheet.id">
      <week-days :sheet="sheet" :disabled="true" :opened="true" />
    </div>
  </article>
</template>

<script>
import WeekDays from '@/components/WeekDays';
export default {
  name: 'ReportSheetDetails',
  props: {
    report: { type: Object, required: true },
    from: { type: String, required: true },
    until: { type: String, required: true },
  },
  components: {
    WeekDays,
  },
  computed: {
    sheets() {
      const sheetsById = this?.report?.sheetsById ?? {};
      const entries = Object.entries(sheetsById);
      const sheets = entries.map(([id, sheet]) => ({ ...sheet, id }));
      return sheets;
    },
    totalsByDays() {
      return (
        this?.sheets?.reduce(
          (acc, sheet) => ({ ...acc, ...sheet.totalsByDay }),
          {}
        ) ?? {}
      );
    },
    timeEntriesByDay() {
      return (
        this?.sheets?.reduce(
          (acc, sheet) => ({ ...acc, ...sheet.timeEntriesByDay }),
          {}
        ) ?? {}
      );
    },
    days() {
      return Object.keys(this.timeEntriesByDay);
    },
  },
};
</script>
