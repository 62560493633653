import { DateTime } from 'luxon';

export default function (weekYear) {
  const now = new DateTime.local();
  const weekFrom = DateTime.fromObject({
    weekYear,
    weekNumber: 1,
  });
  const rightUntil = DateTime.fromObject({
    weekYear,
    weekNumber: weekFrom.weeksInWeekYear,
  });

  let weekUntil;
  if (rightUntil < now.startOf('year')) {
    weekUntil = rightUntil;
  } else if (weekFrom > now.endOf('year')) {
    weekUntil = weekFrom;
  } else {
    weekUntil = now.minus({ week: 1 }).startOf('week');
  }

  return {
    weekFrom: weekFrom.toISOWeekDate().replace(/-1$/, ''),
    weekUntil: weekUntil.toISOWeekDate().replace(/-1$/, ''),
    rightUntil: rightUntil.toISOWeekDate().replace(/-1$/, ''),
  };
}
