const Duration = require('luxon').Duration;

module.exports = function (a, b) {
  if (!a && b) return b;
  else if (!b && a) return a;
  else if (!a && !b) return null;
  let ad = Duration.fromISO(a);
  let bd = Duration.fromISO(b);
  return ad.plus(bd).toISO();
};
