<template>
  <div class="level-item" :class="itemClass" :data-tooltip="tooltip">
    <span v-if="total.negative">-</span>
    <span v-if="total.duration">{{ total.duration | durationTime }}</span>
    <span v-if="total.duration && total.day">+</span>
    <span v-if="total.day">{{ total.day | days }}</span>
    <span class="icon"><fa :icon="['fal', total.icon]" /></span>
  </div>
</template>

<script>
import durationTime from '../filters/durationTime';
import days from '../filters/days';
export default {
  name: 'WeekDayTotal',
  filters: {
    durationTime,
    days,
  },
  props: {
    total: Object,
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    tooltip() {
      return (
        (this.total.tooltip18 && this.total.tooltip18[this.locale]) ||
        this.total.tooltip ||
        (this.total.name18 && this.total.name18[this.locale]) ||
        this.total.name
      );
    },
    itemClass() {
      let result = { tooltip: this.tooltip };
      if (this.total.color) {
        result['has-text-' + this.total.color] = true;
      }
      return result;
    },
  },
};
</script>
