<template>
  <div class="modal is-active">
    <div class="modal-background" @click="close()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ labelTr }}</p>
        <button class="delete" aria-label="close" @click="close()"></button>
      </header>
      <div class="modal-card-body">
        <div class="field">
          <sqr-input-text
            v-model="filter"
            placeholder="entry_model_modal_filter"
          />
        </div>
      </div>
      <div class="modal-card-body">
        <div class="field">
          <div class="menu">
            <ul class="menu-list">
              <li class="menu-item" v-for="model in results" :key="model.name">
                <a @click="select(model)" class="flex items-center">
                  <span
                    style="height: 1.5rem; width: 1rem; display: inline-block"
                    :style="{ 'border-left': '5px solid ' + model.color }"
                  ></span>
                  <span class="icon"><fa :icon="['fal', model.icon]" /></span>
                  <span>&puncsp;</span>
                  <span>{{
                    (model.name18 && model.name18[locale]) || model.name
                  }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="close()"
    ></button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Fuse from 'fuse.js';
import input from '@/sqrd/mixins/input';
import modal from '@/sqrd/mixins/modal';
import SqrInputText from '../sqrd/SqrInputText';

export default {
  name: 'EntryModelModal',
  components: { SqrInputText },
  mixins: [input, modal],
  computed: {
    ...mapGetters('user', ['loading', 'error']),
    ...mapGetters('company', { company: 'record' }),
    entryModels() {
      return this.company?.entryModels ?? [];
    },
    locale() {
      return this.$i18n.locale;
    },
    results() {
      let results = this.entryModels;
      if (this.filter) {
        let fuse = new Fuse(results, {
          keys: ['name', 'name18.en', 'name18.fr'],
          threshold: 0.3,
          distance: 100,
          shouldSort: true,
          matchAllTokens: true,
        });
        results = fuse.search(this.filter);
      }
      return results;
    },
    model() {
      return this.entryModels.find(m => m.id === this.value);
    },
  },
  data() {
    return { filter: '' };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    select(model) {
      this.$emit('select', model);
      this.$emit('close');
    },
  },
};
</script>
