const groupBy = require('../utils/groupBy');

const durationSum = require('../common/durationSum');
const daysSum = require('../common/daysSum');

module.exports = function (entries) {
  const groups = groupBy(entries, 'op');
  const totals = Object.entries(groups).map(pair => {
    return pair[1].reduce((acc, entry) => {
      const duration = durationSum(acc.duration, entry.duration);
      const day = daysSum(acc.day, entry.day);
      return {...acc, duration, day};
    });
  });
  return totals;
};