<template>
  <div class="flex flex-wrap justify-start" v-if="summaries">
    <div
      v-for="sum in summaries"
      :key="sum.op"
      class="flex-1 text-center px-2 mx-1 my-4 lg:my-0 hover:bg-gray-100 rounded-lg"
    >
      <div
        class="text-xs lg:text-sm xl:text-base font-medium uppercase truncate gray-600"
      >
        <span class="mr-2"><fa :icon="['fal', sum.icon]" /></span>
        <span>{{ sum | name }}</span>
      </div>
      <div class="text-xl md:text-2xl lg:text-4xl xl:text-5xl font-semibold">
        <span>{{ sum | opDuration }}</span>
      </div>
      <!-- <a>{{$t('summaries_details')}}</a> -->
    </div>
  </div>
</template>

<script>
import name from '@/filters/name';
import opDuration from '@/filters/opDuration';

export default {
  name: 'Summaries',
  props: {
    summaries: { type: Array, default: () => [] },
  },
  filters: {
    name,
    opDuration,
  },
};
</script>
