export default {
  data() {
    return { toggled: false };
  },
  computed: {
    toggleIcon() {
      return this.toggled ? ['fal', 'chevron-down'] : ['fal', 'chevron-right'];
    },
    toggleClass() {
      return {
        'is-active': this.toggled && !this.disabled,
      };
    },
  },
  methods: {
    toggle() {
      this.toggled = !this.toggled;
    },
  },
};
