const models = function () {
  return [
    {
      op: 'max',
      icon: 'arrow-to-top',
      name: 'max',
      name18: {en: 'Max', fr: 'Maximum', de: 'Max'},
      hide: true
    },
    {
      op: 'due',
      icon: 'bullseye',
      name: 'due',
      name18: {en: 'Due', fr: 'Dû', de: 'Fällig'}
    },
    {
      op: 'normal',
      icon: 'building',
      name: 'normal time',
      name18: {en: 'Normal time', fr: 'Temps normal', de: 'Normal Zeit'}
    },
    {
      op: 'recovery',
      icon: 'recycle',
      name: 'recovery',
      name18: {en: 'Recovery', fr: 'Récup.', de: 'Wiederherstellung'}
    },
    {
      op: 'over-time',
      icon: 'plus-circle',
      name: 'overtime',
      name18: {en: 'overtime', fr: 'Heures sup.', de: 'Überstunden'}
    },
    {
      op: 'extra-time',
      icon: 'plus-hexagon',
      name: 'extratime',
      name18: {en: 'Extra time', fr: 'Travail sup.', de: 'Mehrarbeit'}
    },
    {
      op: 'total-worked',
      icon: 'sigma',
      name: 'total worked',
      name18: {en: 'Total Worked', fr: 'Total Travail', de: 'Gesamtarbeits'}
    },
    {
      op: 'service',
      icon: 'compass',
      name: 'service',
      name18: {en: 'Civil Service', fr: 'Service Civil', de: 'Öffentlicher Dienst'}
    },
    {
      op: 'leave',
      icon: 'leaf',
      name: 'leave',
      name18: {en: 'Leave', fr: 'Absence', de: 'Abwesenheit'}
    },
    {
      op: 'maternity-leave',
      icon: 'baby',
      name: 'maternity leave',
      name18: {en: 'Maternity Leave', fr: 'Congé de Maternité', de: 'Mutterschaftsurlaub'}
    },
    {
      op: 'sick',
      icon: 'stethoscope',
      name: 'sick',
      name18: {en: 'Sick', fr: 'Maladie', de: 'Krank'}
    },
    {
      op: 'sick-child',
      icon: 'child',
      name: 'sick child',
      name18: {en: 'Sick child', fr: 'Maladie enfant', de: 'Krankes Kind'}
    },
    {
      op: 'vacation',
      icon: 'sun',
      name: 'vacation',
      name18: {en: 'Holidays', fr: 'Vacances', de: 'Ferien'}
    },
    {
      op: 'holiday',
      icon: 'bell',
      name: 'holiday',
      name18: {en: 'Bank holidays', fr: 'Fériés', de: 'Feiertagen'}
    },
    {
      op: 'pause',
      icon: 'coffee',
      name: 'pause',
      name18: {en: 'Break', fr: 'Pause', de: 'Pause'}
    }
  ];
};

module.exports = models;