import Vue from 'vue';
import { DateTime } from 'luxon';

export default function (value) {
  if (!value) return '';
  if (typeof value === 'string') {
    return DateTime.fromISO(value)
      .setLocale(Vue.$locale.locale)
      .toLocaleString(DateTime.DATE_FULL);
  } else if (typeof value === 'object') {
    return DateTime.fromMillis(value.toMillis())
      .setLocale(Vue.$locale.locale)
      .toLocaleString(DateTime.DATE_FULL);
  }
}
