<template>
  <div class="field">
    <label class="label">{{ $t('reports_actions') }}</label>
    <div class="buttons">
      <sqr-button :label-raw="String(year - 1)" @click="select(year - 1)" />
      <sqr-button :label-raw="String(year)" @click="select(year)" />
      <sqr-button :label-raw="String(year + 1)" @click="select(year + 1)" />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import SqrButton from '@/sqrd/SqrButton';
export default {
  name: 'ReportButtons',
  components: { SqrButton },
  computed: {
    year() {
      return DateTime.local().weekYear;
    },
  },
  methods: {
    select(weekYear) {
      this.$emit('selected', weekYear);
    },
  },
};
</script>
