import Vue from 'vue';
// import {isNil} from 'ramda';

export default function (value) {
  if (typeof value !== 'number') return '';
  let formated = Math.round(value * 100) / 100.0;
  let unit = 'd';
  switch (Vue.$locale.locale) {
    case 'de':
      unit = 't';
      break;
    case 'fr':
      unit = 'j';
      break;
  }
  return formated + unit;
}
