const DateTime = require('luxon').DateTime;
const Interval = require('luxon').Interval;
const computeSumsByIcon = require('../common/computeSumsByIcon');
const flatMap = require('../utils/flatMap');
const summariesFactor = require('../common/summariesFactor');
const {mergeWith} = require('ramda');

module.exports = function (query, sheets) {

  let startDate = DateTime.fromISO(query.startWeek + '-1');
  let endDate = DateTime.fromISO(query.endWeek + '-1').endOf('week');
  let selection = Interval.fromDateTimes(startDate, endDate);

  let sheetsSelected = sheets.filter(sheet => {
    return selection.contains(DateTime.fromISO(sheet.startDate)) && selection.contains(DateTime.fromISO(sheet.endDate));
  });

  let acceptedCount = sheetsSelected.filter(sheet => sheet.status === 'accepted').length;
  const merge = mergeWith((a, b) => a || 0 + b || 0);
  const noticesCount = sheetsSelected.map(sheet => sheet.noticesCount || {}).reduce((prev, curr) => merge(prev, curr), {});

  let effStartDate = sheetsSelected.length > 0 ? DateTime.min(...sheetsSelected.map(sheet => DateTime.fromISO(sheet.startDate))) : null;
  let effEndDate = sheetsSelected.length > 0 ? DateTime.max(...sheetsSelected.map(sheet => DateTime.fromISO(sheet.endDate))) : null;
  let totals = flatMap(sheetsSelected, s => s.totals);
  let summariesSums = flatMap(sheetsSelected, s => s.summaries);
  let summaries = summariesFactor(summariesSums);

  let result = {
    startWeek: startDate.toISODate(),
    endWeek: endDate.toISODate(),

    startDate: effStartDate ? effStartDate.toISODate() : null,
    endDate: effEndDate ? effEndDate.toISODate() : null,

    sheetsCount: sheetsSelected.length,
    noticesCount,
    acceptedCount,

    totals: computeSumsByIcon(totals),
    summaries: computeSumsByIcon(summaries)
  };

  return result;
};
